import { css, styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { media, selectTheme } from '@creditas-ui/utilities'

import { ArrowDownIcon } from 'components/Icons'

const Wrapper = styled.button`
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0px;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid ${selectTheme('colors.neutral.15')};

  ${media.up('5xl')} {
    padding: 48px 0px;
    height: 124px;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    & > * {
      font-weight: 700;
    }
  }
`

const expandedStyle = () => ({
  title: css`
    font-weight: 700;
  `,
  icon: css`
    transform: rotate(-180deg);
  `,
})

const Title = styled(Typography)(
  ({ isExpanded, template, theme }) => css`
    text-align: left;
    color: ${selectTheme(`colors.neutral.${template === 'dark' ? '0' : '90'}`)({
      theme,
    })}};

    ${isExpanded && expandedStyle().title};
  `,
)

const Icon = styled(ArrowDownIcon)(
  ({ isExpanded, template, theme }) => css`
    display: block;
    margin-right: 24px;
    height: 24px;
    width: 24px;
    transform: rotate(0deg);
    transition: all 0.3s ease-in 50ms;
    path {
      stroke: ${selectTheme(
        `colors.neutral.${template === 'dark' ? '0' : '90'}`,
      )({ theme })};
    }

    ${media.up('5xl')} {
      height: 32px;
      width: 32px;
    }

    ${isExpanded && expandedStyle().icon}
  `,
)

export { Wrapper, Title, Icon }

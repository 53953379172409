import { css, styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { selectTheme } from '@creditas-ui/utilities'

const Wrapper = styled(Typography)(
  ({ template, theme }) => css`
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 4px solid ${selectTheme(template)({ theme })};
    text-transform: uppercase;
    color: ${selectTheme(template)({ theme })};
  `,
)

export { Wrapper }

import { css, styled } from '@creditas-ui/system'
import { getTypography, selectTheme } from '@creditas-ui/utilities'

import { ArrowRightIcon } from 'components/Icons'

const Wrapper = styled.a(
  ({ size, theme }) => css`
    text-decoration: none;
    display: flex;
    align-items: center;
    color: ${selectTheme('colors.primary.60')({ theme })};

    ${getTypography(size === 'md' ? 'bodyMdMedium' : 'bodyXlMedium')({
      theme,
    })};

    &:hover {
      text-decoration: underline;
    }
  `,
)

const ArrowIcon = styled(ArrowRightIcon)`
  margin-left: 8px;
  color: ${selectTheme('colors.primary.60')};
`

export { Wrapper, ArrowIcon }

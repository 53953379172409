import { css, styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { selectTheme } from '@creditas-ui/utilities'

import LinkComponent from '../Link'

const Container = styled.li`
  list-style: none;
  width: 100%;
`

const Text = styled(Typography)(
  ({ template, theme }) => css`
    margin: 32px 24px 40px;
    color: ${selectTheme(`colors.neutral.${template === 'dark' ? '0' : '90'}`)({
      theme,
    })};

    p:not(:empty) + p {
      margin-top: 24px;
    }

    ul,
    ol {
      margin-top: 24px;
      padding-left: 32px;
    }

    hr {
      margin-top: 24px;
    }

    a {
      color: ${selectTheme(
        `colors.neutral.${template === 'dark' ? '0' : '60'}`,
      )({ theme })};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  `,
)

const Link = styled(LinkComponent)`
  margin: 0 24px 32px;
`

export { Container, Link, Text }

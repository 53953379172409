import { node, oneOf } from 'prop-types'

import { Wrapper } from './styles'

const propTypes = {
  backgroundColor: oneOf(['white', 'dark']),
  children: node,
}

const Title = ({ backgroundColor, children }) => (
  <Wrapper
    as="h2"
    template={
      backgroundColor === 'dark' ? 'colors.neutral.0' : 'colors.neutral.90'
    }
    variant="exceptionMdBold"
  >
    {children}
  </Wrapper>
)

Title.propTypes = propTypes

export default Title

import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import LinkComponent from './components/Link'

const Wrapper = styled.section(
  ({ backgroundColor, theme }) => css`
    padding: 64px 0;
    ${backgroundColor === 'dark' &&
    css`
      background-color: ${selectTheme('colors.neutral.90')({ theme })};
    `}

    ${media.up('5xl')} {
      padding: 164px 0;
    }
  `,
)

const Link = styled(LinkComponent)`
  margin-top: 40px;
`

const AccordionList = styled.ul`
  list-style: none;
  width: 100%;
`

export { Wrapper, Link, AccordionList }

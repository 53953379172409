export const removeOuterTagFromHtml = (htmlString, tag) => {
  const endTagRegex = new RegExp(`</${tag}>`, 'g')
  const startTagRegex = new RegExp(`<${tag}>`, 'g')

  const withoutStartTag = htmlString.replace(startTagRegex, '')
  const resultWithoutEndTag = withoutStartTag.replace(endTagRegex, '')
  return resultWithoutEndTag
}

const extractRichTextString = (
  richText,
  shouldRemoveOuterTag = false,
  outerTag,
) => {
  if (!richText) return ''
  const richTextContent = richText.childContentfulRichText || richText

  const htmlString =
    (richTextContent && richTextContent.html) || richTextContent

  return shouldRemoveOuterTag && outerTag
    ? removeOuterTagFromHtml(htmlString, outerTag)
    : htmlString
}

export default extractRichTextString

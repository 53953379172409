import { array } from 'prop-types'
import { Helmet } from 'react-helmet'

import extractRichTextString from 'utils/extractRichTextString'
import removeHtmlTagsFromString from 'utils/removeHtmlTagsFromString'

import getFaqStructuredData from './utils/getFaqStructuredData'

const propTypes = {
  questions: array,
}

const StructuredData = ({ questions }) => {
  const data =
    questions?.map(({ answer, question }) => ({
      question: question || '',
      answer: removeHtmlTagsFromString(extractRichTextString(answer)),
    })) || []

  return (
    <Helmet>
      <script type="application/ld+json">{getFaqStructuredData(data)}</script>
    </Helmet>
  )
}

StructuredData.propTypes = propTypes

export default StructuredData

import React, { useState } from 'react'
import { node, number, oneOf, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import Body from './Body'
import Header from './Header'

import { Container, Link, Text } from './styles'

const propTypes = {
  backgroundColor: oneOf(['white', 'dark']),
  children: node,
  id: string,
  index: number,
  redirectUrl: string,
  title: string,
}

const defaultProps = {
  title: '',
}

const Accordion = ({
  backgroundColor,
  children,
  id,
  index,
  redirectUrl,
  title,
  ...others
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { triggerEvent } = useTriggerEvents()

  const headerId = `accordion-header-${id}`

  const handleClick = () => {
    setIsExpanded(wasExpanded => {
      triggerEvent({
        label: `${index + 1} | ${title} | ${wasExpanded ? 'close' : 'open'}`,
        pageInteraction: true,
      })

      return !wasExpanded
    })
  }

  const handleLinkClick = () => {
    triggerEvent({
      label: `${index + 1} | ${title} | ${redirectUrl}`,
      pageInteraction: false,
    })
  }

  return (
    <Container {...others}>
      {title && (
        <Header
          id={headerId}
          backgroundColor={backgroundColor}
          onClick={handleClick}
          isExpanded={isExpanded}
        >
          {title}
        </Header>
      )}

      {children && (
        <Body id={id} headerId={headerId} isExpanded={isExpanded}>
          <>
            <Text variant="bodyMdRegular" as="div" template={backgroundColor}>
              <div dangerouslySetInnerHTML={{ __html: children }} />
            </Text>

            {redirectUrl && (
              <Link
                href={redirectUrl}
                index={index}
                label="Veja todas as perguntas"
                onClick={handleLinkClick}
                size="md"
              />
            )}
          </>
        </Body>
      )}
    </Container>
  )
}

Accordion.propTypes = propTypes
Accordion.defaultProps = defaultProps

export default Accordion

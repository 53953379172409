import { bool, node, number, string } from 'prop-types'

import { animationProps, Wrapper } from './styles'

const propTypes = {
  children: node,
  headerId: string,
  id: string,
  index: number,
  isExpanded: bool,
}

const Body = ({ children, headerId, id, isExpanded }) => {
  return (
    <Wrapper
      id={id}
      aria-labelledby={headerId}
      aria-hidden={!isExpanded}
      {...animationProps}
      animate={isExpanded ? 'open' : 'collapsed'}
      data-testid={`accordion-body-${id}`}
    >
      {children}
    </Wrapper>
  )
}

Body.propTypes = propTypes

export default Body

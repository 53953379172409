import { bool, func, node, oneOf, string } from 'prop-types'

import { Icon, Title, Wrapper } from './styles'

const propTypes = {
  backgroundColor: oneOf(['white', 'dark']),
  children: node,
  id: string,
  isExpanded: bool,
  onClick: func,
}

const Header = ({ backgroundColor, children, id, isExpanded, onClick }) => {
  return (
    <Wrapper
      id={id}
      aria-expanded={isExpanded}
      aria-controls={id}
      onClick={onClick}
    >
      {children && (
        <Title
          as="span"
          template={backgroundColor}
          variant="bodyXLLight"
          isExpanded={isExpanded}
        >
          {children}
        </Title>
      )}
      <Icon
        aria-hidden="true"
        template={backgroundColor}
        isExpanded={isExpanded}
      />
    </Wrapper>
  )
}

Header.propTypes = propTypes

export default Header

import { styled } from '@creditas-ui/system'
import { motion } from 'framer-motion'

const Wrapper = styled(motion.div)`
  overflow: hidden;
  text-align: left;
`

const animationProps = {
  initial: 'collapsed',
  variants: {
    collapsed: {
      height: '0px',
    },
    open: {
      height: 'auto',
    },
  },
  transition: {
    duration: 0.3,
    property: 'transform',
    transitionTiming: 'ease-in',
    delay: 0.05,
  },
}

export { Wrapper, animationProps }

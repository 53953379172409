const getFaqStructuredData = (questions = []) => {
  const questionsToMainEntity = questions.map(({ answer, question }) => {
    return {
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    }
  })

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questionsToMainEntity,
  })
}

export default getFaqStructuredData

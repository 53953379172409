import React from 'react'
import { arrayOf, object, oneOf, oneOfType, shape, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { Container } from 'components/Container'

import Accordion from './components/Accordion'
import StructuredData from './components/StructuredData'
import Title from './components/Title'

import { AccordionList, Link, Wrapper } from './styles'

const propTypes = {
  backgroundColor: oneOf(['white', 'dark']),
  questions: arrayOf(
    shape({
      answer: oneOfType([object, string]),
      id: string,
      question: string,
      redirectUrl: string,
    }),
  ),
  redirectUrl: string,
  title: string,
}

const defaultProps = {
  backgroundColor: 'white',
  title: 'Perguntas frequentes',
  questions: [],
}

const Faq = ({ backgroundColor, questions, redirectUrl, title, ...others }) => {
  const { triggerEvent } = useTriggerEvents()

  const sectionTitle = title || defaultProps.title
  const linkLabel = 'Veja todas as perguntas'
  backgroundColor = backgroundColor ?? defaultProps.backgroundColor

  const handleLinkClick = () => {
    triggerEvent({
      label: linkLabel,
      pageInteraction: false,
    })
  }

  return (
    <>
      <StructuredData questions={questions} />

      <Wrapper backgroundColor={backgroundColor} {...others}>
        <Container>
          <Title backgroundColor={backgroundColor}>{sectionTitle}</Title>

          <AccordionList>
            {questions?.map((question, index) => {
              const {
                answer,
                id,
                question: questionTitle,
                redirectUrl: questionRedirectUrl,
              } = question

              return (
                <Accordion
                  key={`accordion-${id}`}
                  title={questionTitle}
                  id={id}
                  index={index}
                  redirectUrl={questionRedirectUrl}
                  backgroundColor={backgroundColor}
                >
                  {answer}
                </Accordion>
              )
            })}
          </AccordionList>

          {redirectUrl && (
            <Link
              href={redirectUrl}
              label={linkLabel}
              size="xl"
              onClick={handleLinkClick}
            />
          )}
        </Container>
      </Wrapper>
    </>
  )
}

Faq.propTypes = propTypes
Faq.defaultProps = defaultProps

export default Faq

import { oneOf, string } from 'prop-types'

import { ArrowIcon, Wrapper } from './styles'

const propTypes = {
  href: string,
  label: string,
  size: oneOf(['xl', 'md']),
}

const Link = ({ href, label, size, ...others }) => (
  <Wrapper href={href} size={size} target="_blank" rel="noopener" {...others}>
    {label}
    <ArrowIcon />
  </Wrapper>
)

Link.propTypes = propTypes

export default Link
